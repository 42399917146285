<script lang='ts'>
  import { RESULTS_PER_PAGE } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { findIsoByLangId } from '@/helpers/findHelpers'
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import AudioPlayer from '../audio/AudioPlayer.svelte'
  import TaskTabs from './TaskTabs.svelte'

  import { TaskSrcProp, UserTask, UserTaskCount } from '@/definitions/langoid'

  export let userId: number,
    isMyProfile = false,
    userLanguages: number[],
    selectedLang: number
  let tasksList: UserTask[] = []
  let taskCounts: UserTaskCount = {
    listen: 0,
    read: 0,
    story: 0,
    translate: 0
  }
  let loadMore = false
  let selected = 'story'
  let isInitialLoad = true
  let taskSrcProps: TaskSrcProp[] = []
  let cursor: string | undefined = ''
  $: if (selected || selectedLang) {
    if (isInitialLoad) {
      isInitialLoad = false
    } else {
      cursor = ''
      tasksList = []
      loadTasks()
    }
  }

  async function loadTasks () {
    const data = await fetchData('social/profileTasksLoad', { cursor, selectedLang, slug: selected, userId })
    if (!data) return
    if (data.tasks.length > RESULTS_PER_PAGE) {
      const last = data.tasks.pop()
      cursor = last?.created_at
      loadMore = true
    } else {
      loadMore = false
    }
    tasksList = [...tasksList, ...data.tasks]
    taskCounts = data.taskCounts
    taskSrcProps = tasksList.map((i) => ({
      i,
      src: i.audioContent || i.audio_content
    }))
  }

  loadTasks()

</script>

<div class='header-block'>
  <h4>{$_('social.tasks')}</h4>
  {#if userLanguages.length > 1}
    {#if isMyProfile}
      <LanguageSelect bind:selectedLanguage={selectedLang} />
    {:else}
      <LanguageSelect allowed={userLanguages} bind:selectedLanguage={selectedLang} />
    {/if}
  {/if}
  <span />
</div>
<TaskTabs {taskCounts} bind:selected />
<div class='tasks-list profile-tasks'>
  {#each tasksList as task, i}
    {#key task.id_language}
      <div class='singleTask'>
        <a href={`/${findIsoByLangId(task.id_language)}/task/${selected}/correct/${task.id}`} rel='noopener noreferrer' target='_blank'>
          <span class='language'><Flag id={task.id_language} variant='small' /></span>
          {#if task.audioContent || task.audio_content}
            <AudioPlayer {...taskSrcProps[i]} variant='mini' />
          {/if}
          <p class='content'>{@html task.textContent || task.text_content || ''}</p>
        </a>
      </div>
    {/key}
  {:else}
    <div class='singleTask'>{$_('social.noTasks')}</div>
  {/each}
  {#if loadMore}
    <button
      class='loadMore'
      type='button'
      on:click={() => loadTasks()}
    >{$_('loadMore')}</button>
  {/if}
</div>

<style lang='scss'>
  .header-block {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    align-items: center;
    padding-bottom: 1.6rem;
  }

  .tasks-list {
    padding: 2rem 0;

    > .singleTask {
      position: relative;
      padding: 0.4rem 0;
      border-bottom: none;

      > a {
        display: flex;

        > .language {
          min-width: 4.8rem;
        }

        > p:hover {
          text-decoration: underline;
          color: var(--Primary-Medium);
        }
      }
    }

    > .loadMore {
      display: block;
      margin: 2rem auto;
    }
  }

  @media(max-width: 768px) {
    .header-block {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
</style>
