<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import { UnknownRecord, UserSettings } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  let userSettings: UserSettings = {
    disability: {
      desc: '',
      type: 'none'
    },
    email: {
      corrections: 1,
      friendRequests: 1,
      learningReminders: 0,
      taskComments: 0
    },
    interface: { theme: 'light' },
    mix: { audio: { chat: 0 } },
    privacy: {
      age: 1,
      inSearch: { shown: 1 },
      learningProgress: 1,
      location: 1
    },
    tests: {
      algorithm: {},
      disableAudioTestType: 0,
      mode: 'basic',
      showInputField: 1,
      showTimer: 0,
      skipCorrect: 1
    }
  }

  // Calculate an initial value for form

  const createForm = () => {
    form = {
      disability: {
        desc: userSettings.disability.desc,
        type: userSettings.disability.type
      },
      email: {
        corrections: userSettings.email.corrections === 1,
        friendRequests: userSettings.email.friendRequests === 1,
        learningReminders: userSettings.email.learningReminders === 1,
        taskComments: userSettings.email.taskComments === 1
      },
      // Add rest of the form fields here...
      interface: {
        theme: userSettings.interface.theme
      },
      mix: {
        audio: {
          chat: userSettings.mix.audio.chat === 1
        }
      },
      privacy: {
        age: userSettings.privacy.age === 1,
        inSearch: {
          shown: userSettings.privacy.inSearch.shown === 1
        },
        learningProgress: userSettings.privacy.learningProgress === 1,
        location: userSettings.privacy.location === 1
      },
      tests: {
        algorithm: userSettings.tests.algorithm,
        disableAudioTestType: userSettings.tests.disableAudioTestType === 1,
        mode: userSettings.tests.mode,
        showInputField: userSettings.tests.showInputField === 1,
        showTimer: userSettings.tests.showTimer === 1,
        skipCorrect: userSettings.tests.skipCorrect === 1
      }
    }
  }
  let form: UnknownRecord

  onMount(async () => {
    const data: { settings: UserSettings } = await fetchData('social/loadUserSettings')
    userSettings = data.settings
    document.body.setAttribute('data-theme', data.settings.interface.theme)
    localStorage.setItem('theme', data.settings.interface.theme)
    createForm()
  })

  async function save () {
    // Before saving, map the booleans back into numbers
    userSettings.email.corrections = form.email.corrections ? 1 : 0
    userSettings.email.friendRequests = form.email.friendRequests ? 1 : 0
    userSettings.email.learningReminders = form.email.learningReminders ? 1 : 0
    userSettings.email.taskComments = form.email.taskComments ? 1 : 0
    userSettings.mix.audio.chat = form.mix.audio.chat ? 1 : 0
    userSettings.tests.disableAudioTestType = form.tests.disableAudioTestType ? 1 : 0
    userSettings.tests.showInputField = form.tests.showInputField ? 1 : 0
    userSettings.tests.showTimer = form.tests.showTimer ? 1 : 0
    userSettings.tests.skipCorrect = form.tests.skipCorrect ? 1 : 0
    userSettings.privacy.location = form.privacy.location ? 1 : 0
    userSettings.privacy.age = form.privacy.age ? 1 : 0
    userSettings.privacy.inSearch.shown = form.privacy.inSearch.shown ? 1 : 0
    userSettings.privacy.learningProgress = form.privacy.learningProgress ? 1 : 0
    await fetchData('social/saveUserSettings', { settings: userSettings })

    // set dark mode
    document.body.setAttribute('data-theme', userSettings.interface.theme)
    localStorage.setItem('theme', userSettings.interface.theme)
    notifier.info('Your settings are saved') // TODO: i18n
  }

  function profile () {
    navigate(`/profile/${$userStorredConfig.id}`)
  }

</script>
{#if form}
  <div class='_gap24'>
    <h2>Edit User Settings</h2>
    <hr />
    <form class='_form' on:submit|preventDefault={save}>
      <div>
        <h3>Email</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.email.corrections} />
            <span class='checkbox' />
            Corrections
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.friendRequests} />
            <span class='checkbox' />
            FriendRequests
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.learningReminders} />
            <span class='checkbox' />
            Learning Reminders
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.taskComments} />
            <span class='checkbox' />
            Task Comments
          </label>
        </p>
      </div>
      <div class='not-enabled'>
        <h3>Privacy</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.location} />
            <span class='checkbox' />
            Location
          </label>
          <!-- Add more checkbox for other Privacy fields here -->
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.age} />
            <span class='checkbox' />
            Age
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.inSearch.shown} />
            <span class='checkbox' />
            In Search
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.learningProgress} />
            <span class='checkbox' />
            Learning Progress
          </label>
        </p>
      </div>
      <!-- Add more sections for other categories
         (interface, disability, tests, mix)
         similar to Email and Privacy above -->
      <div>
        <h3>Tests</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.showTimer} />
            <span class='checkbox' />
            Show Timer
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.showInputField} />
            <span class='checkbox' />
            Show Input Field
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.disableAudioTestType} />
            <span class='checkbox' />
            Disable Audio Test Type
          </label>
        </p>
        <p>
          <label>
            Mode
            <select bind:value={userSettings.tests.mode} name=''>
              <option value='basic'>Basic</option>
              <option value='advanced'>Advanced</option>
              <option value='expert'>Expert</option>
            </select>
          </label>
        </p>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.skipCorrect} />
            <span class='checkbox' />
            AutoSkip correct
          </label>
        </p>
      </div>
      <div>
        <h3>Interface</h3>
        <p>
          <label>
            <select bind:value={userSettings.interface.theme} name=''>
              <option value='light'>Light</option>
              <option value='dark'>Dark</option>
            </select>
          </label>
        </p>
        <!--
        Theme
        -->
      </div>
      <div class='not-enabled'>
        <h3>Mix</h3>
        <label>
          <input type='checkbox' bind:checked={form.mix.audio.chat} />
          Chat
        </label>
      </div>
      <div class='not-enabled'>
        <h3>Disability</h3>
        <label>
          Disability Type
          <select bind:value={userSettings.disability.type} name=''>
            <option value='none'>None</option>
            <option value='deaf'>Deaf</option>
            <option value='blind'>Blind</option>
            <option value='colorblind'>Colorblind</option>
            <option value='other'>Other</option>
          </select>
        </label>
        {#if userSettings.disability.type === 'other'}
          <label>
            Disability Description
            <input type='text' bind:value={userSettings.disability.desc} />
          </label>
        {/if}
      </div>
      <div class='save-or-cancel'>
        <button type='submit'>Save</button>
        <button type='button' on:click={profile}>Cancel</button>
      </div>
    </form>
  </div>
{/if}
<style lang='scss'>
  .not-enabled {
    display: none;
  }

  .save-or-cancel {
    display: flex;
    gap: 0.8rem;
  }
</style>
