<script lang='ts'>
  import { RESULTS_PER_PAGE } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import UserProfileTeaser from './UserProfileTeaser.svelte'

  import { UserFriends } from '@/definitions/langoid'

  export let userId: number

  const selected = 'friends'
  let usersList: UserFriends[] = [],
    cursor = '',
    loadMore = false,
    friendsNumber = 0

  async function profileFriends () {
    const data = await fetchData('social/profileFriends', { cursor, selected, userId })
    if (data.usersList.length > RESULTS_PER_PAGE) {
      const last = data.usersList.pop()
      cursor = last.created_at
      loadMore = true
    } else {
      loadMore = false
    }
    usersList = [...usersList, ...data.usersList]
    friendsNumber = data.friendsNumber.friends
  }

  profileFriends()
</script>
<div>
  <h4
    class='friends-heading'
    class:-selected={selected === 'friends'}
  >
    <span>{$_('profile.friends')}</span>
    <small>({friendsNumber})</small>
  </h4>
</div>
<div class='my-friends'>
  <div class='friends-list'>
    {#each usersList as user}
      <UserProfileTeaser avatarVariant='bigAvatar' {user} variant='myFriends' />
    {:else}
      <p>{$_('profile.noFriends')}</p>
    {/each}
  </div>
  {#if loadMore}
    <button type='button' on:click={profileFriends}>{$_('loadMore')}</button>
  {/if}
</div>
<style lang='scss'>
  .friends-heading {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    padding-bottom: 0.4rem;
  }

  .my-friends {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .friends-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 12rem;
  }
</style>
