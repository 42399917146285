<script lang='ts'>
  import { getIsoFromId, getLearningLanguageId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { UserStat } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  let language: number = getLearningLanguageId()
  let period = 'all'
  let offset: number = 0
  const offsetLimit: number = 20
  let users: UserStat[] = []
  let moreUsers: UserStat[] = []
  let groups: { k: number, v: number }[] = []
  let showPointGroupsWrapper = false
  const iso = getIsoFromId($userStorredConfig.id_lang_learning)
  const breads = [
    { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
    { text: $_('menu.statsAll') }
  ]

  loadStats(language, period, offset)

  type RecordWithPoints = { points: number }

  async function loadStats (language: number, period: string, offset: number) {
    const data = await fetchData('social/rangList', { language, offset, period })
    users = data.results.filter((e: RecordWithPoints) => e.points > 0)
    const tmpGroups = []
    for (const i in data.groups) {
      tmpGroups.push({ k: parseInt(i), v: data.groups[i] as number })
    }
    groups = tmpGroups
  }

  function refreshStat (): void {
    offset = 0
    loadStats(language, period, offset)
  }

  function setPeriod (p: string): void {
    period = p
    refreshStat()
  }

  async function loadMoreUsers () {
    offset += offsetLimit
    const data = await fetchData('social/rangList', { language, offset, period })
    moreUsers = data.results.filter((e: RecordWithPoints) => e.points > 0)
    users = [...users, ...moreUsers]
  }

  function showPointsRank () {
    showPointGroupsWrapper = !showPointGroupsWrapper
  }

  const changeLang = (lang: number) => {
    language = lang
    refreshStat()
    return true
  }
</script>
<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('menu.statsAll')}</h2>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    <FakeButton class='_icon _desktopOnly' onClick={showPointsRank}>
      <Icon icon='UsersFour' weight='regular' />
    </FakeButton>
    <FakeButton class='_mobileOnly' onClick={showPointsRank}>
      <span class='_icon -noBorder '><Icon icon='UsersFour' weight='regular' /></span>
      <span>{$_('stats.numberOfUsers')}</span>
    </FakeButton>
    {#if groups && showPointGroupsWrapper}
      <Overlay onClick={() => {showPointGroupsWrapper = false}}>
        <CloseButton closeVariant='absolute' onClick={() => {showPointGroupsWrapper = false}} />
        <div class='each-user-by-group'>
          <div class='cellGroupHeader -left'>{$_('stats.numberOfUsers')}</div>
          <div class='cellGroupHeader -right'>{$_('points')}</div>
          {#each groups as group}
            <div class='cellGroup -left'>{group.v}</div>
            <div class='cellGroup -right'>{group.k}+</div>
          {/each}
        </div>
      </Overlay>
    {/if}
    <hr class='_mobileOnly' />
    <PageInfo kebabItem pageName='rangList' />
  </div>
</Breadcrumb>
<div class='_gap24 -unwrapMobile'>
  {#key language}
    <div class='list-users-ranking'>
      <div class='ranking-sort'>
        <div class='list-header'>
          <div class='sort-by-language'>
            <LanguageSelect onChange={changeLang} placeholder={$_('stats.allLanguages')} bind:selectedLanguage={language} />
          </div>
        </div>
        <div class='_tabs-wrapper'>
          <button class:_transparent-gray={period !== 'all'} type='button' on:click={() => setPeriod('all')}>
            {$_('period.all')}</button>
          <!-- <button class:_transparent-gray={period!=='year'} type='button' on:click={()=>setPeriod('year')}>
          {$_('period.year')}</button> -->
          <button class:_transparent-gray={period !== 'month'} type='button' on:click={() => setPeriod('month')}>
            {$_('period.month')}</button>
          <button class:_transparent-gray={period !== 'week'} type='button' on:click={() => setPeriod('week')}>
            {$_('period.week')}</button>
          <button class:_transparent-gray={period !== 'day'} type='button' on:click={() => setPeriod('day')}>
            {$_('period.day')}</button>
        </div>
      </div>
      {#if users}
        <div class='users-ranking'>
          {#each users as user, index}
            {#key user.id}
              <div class='each-user'>
                <div class='rankingCell'>{index + 1}</div>
                <div class='avatarCell'>
                  <UserAvatar metaDataVariant='right' pointsVariant='withPoints' {user} withFlag />
                </div>
              </div>
              <hr />
            {/key}
          {:else}
            <p>{$_('stats.noUsers')}</p>
          {/each}
        </div>
      {/if}
      {#if users.length < offsetLimit || users.length < offset + offsetLimit}
        <button class='loadMoreBtn -disable' type='button' on:click={() => loadMoreUsers()}>Load more</button>
      {:else}
        <button class='loadMoreBtn -primary' type='button' on:click={() => loadMoreUsers()}>Load more</button>
      {/if}
    </div>
  {/key}
</div>

<style lang='scss'>
  .list-users-ranking {
    > .loadMoreBtn {
      &.-disable {
        display: none;
      }

      &.-primary {
        margin: 3rem auto 0;
      }
    }

    > :global(.dropdown-wrapper) {
      width: 35rem;
    }
  }

  .users-ranking {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding-top: 1.6rem;

    > hr:last-child {
      display: none;
    }
  }

  .each-user {
    display: flex;
    gap: 2.4rem;
    align-items: center;
    padding-inline: 1.6rem;
  }

  .ranking-sort {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .list-header {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
  }

  .sort-by-language {
    position: absolute;
    top: 5.6rem;
    right: 2rem;
  }

  .each-user-by-group {
    display: grid;
    grid-template-rows: repeat(11, 1fr);
    grid-template-columns: repeat(2, 1fr);
    min-width: 38.4rem;
    text-align: center;

    > .cellGroup,
    > .cellGroupHeader {
      padding: 0.8rem 0;

      &.-right {
        border-left: 0.1rem solid var(--Gray-Light);
      }
    }

    > .cellGroup {
      border-top: 0.1rem solid var(--Gray-Light);
    }
  }

  @media (max-width: 992px) {
    .sort-by-language {
      position: static;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .each-user-by-group {
      min-width: 23rem;
    }
  }
</style>
