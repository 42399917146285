<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import SearchUserForm from '@/components/social/SearchUserForm.svelte'
  import UserProfileTeaser from '@/components/social/UserProfileTeaser.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { SearchUser } from '@/definitions/langoid'

  let users: SearchUser[] = []
  let offset = '0000-00-00 00:00:00'
  let newOffset = ''
  const limit = 20
  const setUsers = (usersArray: SearchUser[], offsetValue: string = ''): void => {
    users = []
    users = usersArray
    newOffset = offsetValue
  }

  const addUsers = (usersArray: SearchUser[], offsetValue: string = ''): void => {
    users = usersArray
    newOffset = offsetValue
  }

  const handleLoadMore = (): void => {
    offset = newOffset
  }
</script>
<Breadcrumb breads={[{text:'', url: '/'}, {text:'Search users'}]}>
  <h2 class='heading-mobile'>{$_('menu.searchUsers')}</h2>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    <PageInfo kebabItem pageName='searchUsers' />
  </div>
</Breadcrumb>
<div class='search-user-content _gap24'>
  <SearchUserForm {addUsers} num={users.length} {offset} {setUsers} {users} />
  <div class='content-users'>
    {#if users.length > 0}
      {#each users as user}
        <UserProfileTeaser pointsVariant='withPoints' {user} variant='landscape' />
      {/each}

      {#if users.length >= limit && users.length % limit === 0}
        <button class='show-more-button default-button' type='button' on:click={() => { handleLoadMore() }}>
          {$_('loadMore')}</button>
      {:else}
        <button class='show-more-button -disabled default-button' type='button' on:click={() => { handleLoadMore() }}>
          {$_('loadMore')}</button>
      {/if}
    {/if}
  </div>
</div>

<style lang='scss'>
  .search-user-content {
    display: grid;
    grid-template-columns: 30rem 1fr;
    justify-content: center;
  }

  .content-users {
    max-width: 68.4rem;
    border-radius: 0.6rem;
  }

  .show-more-button {
    display: block;
    max-width: 25.6rem;
    margin: 1.2rem auto;

    &.-disabled {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .search-user-content {
      grid-template-columns: 1fr;
    }
  }
</style>
