<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link } from 'svelte-routing'

  import { PER_PAGE_RESULTS } from '@/config/constants'

  import { dateString } from '@/helpers/dateHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { findIsoByLangId } from '@/helpers/findHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import AddFriendButton from '@/components/social/AddFriendButton.svelte'
  import MyFriends from '@/components/social/MyFriends.svelte'
  import ProfileTasks from '@/components/social/ProfileTasks.svelte'
  import ProfileTimeStats from '@/components/social/ProfileTimeStats.svelte'
  import UserMainInfo from '@/components/social/UserMainInfo.svelte'
  import UserSecondaryInfo from '@/components/social/UserSecondaryInfo.svelte'
  import Vocabulary from '@/components/social/Vocabulary.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { ApiProfileLoad, TimeStats, UserProfile, UserVocabulary } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  interface MyWindow extends Window {
    [key: string]: any;
  }

  export let userId: number
  let selectedLang: number
  let badges: { count: number; index: number }[] = []
  let user: UserProfile = {} as UserProfile
  let vocabulary: Record<number, UserVocabulary[]> = {}
  let stats: TimeStats = {} as TimeStats
  let isMyProfile: boolean
  let receivedRequest: boolean
  let sentRequestMessage: string
  let userLanguages: number[] = []
  let chatOpenClass = ''
  $: if (userId) {
    getUserData()
  }
  let language: number = toInt($userStorredConfig.id_lang_learning)
  const period = 'month'
  const offset = -30
  const start = dateString('day', offset)
  const end = dateString('day')
  loadStats(language, period, start, end)
  const style = getComputedStyle(document.body)

  async function loadStats (language: '0' | number, period: string, start: string, end: string) {
    const data = await fetchData('social/stats', { end, language, period, start })
    showChart(data.points, 'pointsChart')
    const dataCards = { ...data.new, result: {} }
    if (data.new.result.new.length > 0) {
      dataCards.result.new = data.new.result.new
    }
    if (data.leveled.result.leveled.length > 0) {
      dataCards.result.leveled = data.leveled.result.leveled
    }
    if (data.learned.result.learned.length > 0) {
      dataCards.result.learned = data.learned.result.learned
    }
  }

  $:if (period || start || end || language) {
    loadStats(language, period, start, end)
  }

  async function showChart (data: { points: string[]; result: Record<string, { date: string; num: number; }[]> }, element: string) {
    const colors: Record<string, string> = {
      grid: style.getPropertyValue('--text-background'),
      learned: style.getPropertyValue('--know'),
      leveled: style.getPropertyValue('--good'),
      new: style.getPropertyValue('--seen'),
      points: style.getPropertyValue('--Accent-Light'),
      tests: style.getPropertyValue('--Error-Medium'),
      time: style.getPropertyValue('--Primary-Ligth')
    }
    const datasets = []
    for (const i in data.result) {
      const dataResult = data.result[i]
      const result: number[] = []
      const tmp = {} as Record<string, number>
      if (dataResult) {
        dataResult?.forEach((el: { date: string; num: number; }) => {
          if (tmp[el.date]) {
            tmp[el.date] += el.num
          } else tmp[el.date] = el.num
        })
        data.points?.forEach(item => {
          if (tmp[item]) {
            result.push(tmp[item])
          } else {
            result.push(0)
          }
        })
        datasets.push({
          borderColor: colors[i] ? colors[i] : 'rgb(75, 192, 192)',
          data: result,
          fill: false,
          label: i,
          lineTension: 0.1
        })
      }
    }
    const canvasEl: HTMLCanvasElement = document.getElementById(element) as HTMLCanvasElement
    const ctx = canvasEl ? canvasEl.getContext('2d') : undefined
    if (ctx) {
      const myWindow = window as MyWindow
      const windowKey: string = `My${element}`
      const { Chart } = await import('@/helpers/chart')
      if (myWindow[windowKey]) {
        myWindow[windowKey].destroy()
      }
      myWindow[windowKey] = new Chart(ctx, {
        data: { datasets, labels: data.points },
        options: {
          plugins: { legend: { position: 'top' } },
          scales: { x: { grid: { color: colors.grid } }, y: { grid: { color: colors.grid } } }
        },
        type: 'line'
      })
    }
  }

  async function getUserData () {
    const data: ApiProfileLoad = await fetchData('social/profileLoad', { userId })
    for (let i = 1; i <= PER_PAGE_RESULTS; i++) {
      if (data.badges[i]) {
        const tmp = data.badges[i]
        const count = Object.keys(tmp).length
        badges.push({ count, index: i })
      } else {
        badges.push({ count: 0, index: i })
      }
    }
    badges = [...badges]
    user = data.user || {} as UserProfile
    userLanguages = data.languages
    vocabulary = data.vocabulary
    if (Object.keys(vocabulary).length === 1) {
      selectedLang = toInt(Object.keys(vocabulary)[0])
    } else if (user.id_lang_learning) {
      selectedLang = user.id_lang_learning
    }
    stats = data.time
    isMyProfile = data.isMyProfile
    receivedRequest = data.receivedFriendRequests
    sentRequestMessage = data.sentRequestMessage
  }

  function toggleChatMenu (): void {
    chatOpenClass = chatOpenClass === '-open' ? '' : '-open'
  }

  const unsubscribe = userStorredConfig.subscribe((value) => {
    // todo - at the moment only for updating icon for interface language
    user.id_lang_interface = value.id_lang_interface
  })
  onDestroy(() => {
    unsubscribe()
  })
</script>
<div class='_gap24 -unwrapMobile'>
  <div class='profile-avatar'>
    {#if isMyProfile}
      <div class='profile-links'>
        <a class='editProfile' href='/edit-profile' title={$_('profile.editProfileTitle')} use:link>
          <Icon icon='PencilSimple' />
        </a>
        <!-- todo - enable settings edit only for premium users -->
        <a class='editSettings' href='/settings' title={$_('profile.settings')} use:link>
          <Icon icon='Gear' />
        </a>
      </div>
    {:else}
      <div class='profile-links'>
        <ReportButton category='user' rel={user.id} />
      </div>
    {/if}
    <UserMainInfo {user} />
    <div class='add-send-message'>
      {#if !isMyProfile}
        <div class='messageByLanguage' class:-open={chatOpenClass === '-open'}>
          {#each userLanguages as lang}
            <a href={`/${findIsoByLangId(lang)}/chat/${user.id}`} use:link>
              <Flag id={lang} variant='full' />
            </a>
          {/each}
        </div>
        <button
          class='sendMessage'
          type='button'
          on:click={toggleChatMenu}
          on:keypress={toggleChatMenu}
        >
          {$_('profile.sendMessage')}
        </button>
        <AddFriendButton friendId={user.id} {receivedRequest} {sentRequestMessage} />
      {/if}
    </div>
  </div>
  <div class='profile-info'>
    <UserSecondaryInfo {isMyProfile} languages={userLanguages} {user} />
    <ProfileTimeStats {isMyProfile} {stats} />
  </div>
</div>
<div class=' _gap24 -unwrapMobile'>
  <div class='header-block'>
    <h4>{$_('profile.vocabulary')}</h4>
    {#if userLanguages.length > 1}
      <div class='select-language _desktopOnly'>
        {#if isMyProfile}
          <LanguageSelect bind:selectedLanguage={selectedLang} />
        {:else}
          <LanguageSelect allowed={userLanguages} bind:selectedLanguage={selectedLang} />
        {/if}
      </div>
    {/if}
    <a class='moreInfo' class:-show={isMyProfile} href='/vocabulary' use:link>
      <Icon icon='BookOpenText' />
      <span class='link'>{$_('levelPage.vocabulary')}</span>
      <span class='arrow'>→</span>
    </a>
  </div>
  {#if userLanguages.length > 1}
    <div class='select-language _mobileOnly'>
      {#if isMyProfile}
        <LanguageSelect bind:selectedLanguage={selectedLang} />
      {:else}
        <LanguageSelect allowed={userLanguages} bind:selectedLanguage={selectedLang} />
      {/if}
    </div>
  {/if}
  <Vocabulary {selectedLang} {vocabulary} />
</div>
<div class=' _gap24 -unwrapMobile'>
  <div class='profile-stats-graph'>
    <div class='header-block'>
      <h4>{$_('profile.stats')}</h4>
      {#if userLanguages.length > 1}
        <div class='select-language _desktopOnly'>
          <LanguageSelect bind:selectedLanguage={language} />
        </div>
        <a class='moreInfo' class:-show={isMyProfile} href='/stats' use:link>
          <Icon icon='ChartBar' />
          <span class='link'>{$_('profile.statsDetails')}</span>
          <span class='arrow'>→</span>
        </a>
      {/if}
    </div>
    {#if userLanguages.length > 1}
      <div class='select-language _mobileOnly'>
        <LanguageSelect bind:selectedLanguage={language} />
      </div>
    {/if}
    {#if isMyProfile} <!-- If we deside to alow that everyone can see everyones stats, we have to send other appropriate props to LanguageSelect component -->
      <div class='charts-wrapper'>
        <p class='label'>{$_('points')}x</p>
        <canvas id='pointsChart' />
      </div>
    {/if}
  </div>
</div>
  {#if false}
  <div class='profile-task-list -badges'>
    <h4>{$_('profile.achievements')}</h4>
    <div class='lock-achievements'>
      {#each badges as badge}
        {#if badge.count === 1}
          <img alt='1' src='/images/badges/{badge.index}-bronze.svg' width='50' />
        {:else if badge.count === 2}
          <img alt='2' src='/images/badges/{badge.index}-silver.svg' width='50' />
        {:else if badge.count === 3}
          <img alt='3' src='/images/badges/{badge.index}-gold.svg' width='50' />
        {:else}
          <Icon icon='LockSimple' />
        {/if}
      {/each}
    </div>
  </div>
{/if}
<div class=' _gap24 -unwrapMobile'>
  <div class='profile-task-list'>
    <ProfileTasks
      {isMyProfile}
      {selectedLang}
      {userId}
      {userLanguages}
    />
  </div>
</div>
{#key userId}
  <div class=' _gap24 -unwrapMobile'>
    <MyFriends {userId} />
  </div>
{/key}

<style lang='scss'>
  .profile-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;
    justify-content: space-between;
  }

  .lock-achievements {
    > img {
      margin-right: 1.2rem;
    }
  }

  .profile-task-list {
    &.-badges {
      display: none;
    }
  }

  .profile-avatar {
    position: relative;
  }

  .profile-links {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 0.8rem;
  }

  .header-block {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    align-items: center;

    > .moreInfo {
      justify-self: end;
      visibility: hidden;

      &.-show {
        display: flex;
        gap: 1rem;
        font-size: 1.4rem;
        visibility: visible;
      }

      > .link {
        text-decoration: underline;
      }

      > .arrow {
        text-decoration: none;
      }
    }
  }

  .add-send-message {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;

    > .messageByLanguage {
      display: none;

      &.-open {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        overflow: auto;
        max-width: 30rem;
        height: 30rem;

        > a {
          display: inline-block;
          min-width: 8rem;
          height: fit-content;
          padding: 0.8rem;
          font-size: 1.2rem;
          background: var(--inverted-text-color);
          border-radius: 0.8rem;

          > :global(.flag-wrapper) {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header-block {
      display: flex;
      justify-content: space-between;

      > .moreInfo {
        font-size: 1.2rem;
        background-repeat: no-repeat;
        background-position: 1rem -0.2rem;
      }
    }

    .select-language {
      padding-bottom: 1.6rem;
    }
  }
</style>
