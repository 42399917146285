<script lang='ts'>
  import { link } from 'svelte-routing'

  import { _ } from '@/helpers/i18n'
  import { emptyCounts, increaseRating } from '@/helpers/langHelper'
  import { toInt } from '@/helpers/mixHelpers'

  import EmptyState from '@/components/ui/EmptyState.svelte'

  import PieChart from './PieChart.svelte'

  import { ChartValue, UserVocabulary } from '@/definitions/langoid'

  export let vocabulary: Record<number, UserVocabulary[]> = {},
    selectedLang: 'all' | number,
    iso = ''

  let counts = emptyCounts
  let chartValues: ChartValue[] = []
  const style = getComputedStyle(document.body)

  const resetValues = () => {
    chartValues = []
    counts = emptyCounts
  }

  $: if (selectedLang && resetValues) {
    resetValues()

    if (Object.keys(vocabulary).length > 0) {
      if (selectedLang === 'all') {
        Object.keys(vocabulary)?.forEach?.((item: string) => {
          vocabulary[toInt(item)]?.forEach?.((i: UserVocabulary) => {
            counts = increaseRating(i, counts)
          })
        })
      } else {
        vocabulary?.[selectedLang]?.forEach?.((i) => {
          counts = increaseRating(i, counts)
        })
      }

      chartValues = [
        {
          backgroundColor: style.getPropertyValue('--seen'),
          label: $_('vocabularyPage.labels.seen') + ` (${counts.l0})`,
          value: counts.l0
        },
        {
          backgroundColor: style.getPropertyValue('--starting'),
          label: $_('vocabularyPage.labels.starting') + ` (${counts.l1})`,
          value: counts.l1
        },
        {
          backgroundColor: style.getPropertyValue('--good'),
          label: $_('vocabularyPage.labels.good') + ` (${counts.l2})`,
          value: counts.l2
        },
        {
          backgroundColor: style.getPropertyValue('--know'),
          label: $_('vocabularyPage.labels.know') + ` (${counts.l3})`,
          value: counts.l3
        }
      ]
    }
  }
</script>

<div>
  {#if vocabulary}
    <PieChart values={chartValues} />
  {:else}
    <EmptyState title={$_('vocabularyPage.emptyVocabulary')}>
      <div>{$_('vocabularyPage.noData')}</div>
      <a href='/{iso}/lessons/' use:link>{$_('vocabularyPage.goToLessons')}</a>
    </EmptyState>
  {/if}
</div>
